%screen-holder {
  $margin-size: 30px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: $margin-size $margin-size 0 $margin-size;
  flex: 1;
}

%spb-button {
  mat-icon {
    margin-right: 5px !important;
    margin-left: -5px !important;
  }

  mat-icon.loading {
    animation: spin 1s linear infinite;
  }
}

@mixin icon-size($icon-size) {
  font-size: $icon-size;
  height: $icon-size;
  min-height: $icon-size;
  width: $icon-size;
  min-width: $icon-size;
}

$spb-bright-blue: #0069ff;
$header-separation-color: #f3f2f1;
$form-label-color: #000;
$form-value-color: #000000de;
$form-icon-action-color: #0009;
$text-label-color: #696969;
$text-value-color: #000000de;
$text-sub-value-color: #939393;
